import { useState, useEffect, useRef } from 'react';
import './style.less';
import { Input, Form, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Reg from '../../utils/regCheck';
import bg from '../../images/login-bg.png';
import close from '../../images/close-img.png';
import tip from '../../images/tip-img.png';
import { login } from '../../api/login';

const tabs = [{ title: '资金机构' }, { title: '运营机构' }, { title: '企业' }];

const rulesMap = {
  name: {
    emptyTip: '*请输入用户名',
    errorTip: '*用户名格式不正确',
    reg: Reg.nameReg,
    maxLength: 12,
    minLength: 4
  },
  password: {
    emptyTip: '*请输入密码',
    errorTip: '*密码格式不正确',
    reg: Reg.pwReg,
    maxLength: 20,
    minLength: 8
  }
};

const Login = () => {
  const navigate = useNavigate();
  const { from } = useParams();
  const [curIndex, setCurIndex] = useState(0);
  const [isShowTip, setIsShowTip] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLogin = () => {
    formRef.current
      .validateFields()
      .then(values => {
        console.log('valueObj=====>', values);
        _login(values);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const _login = async params => {
    try {
      const res = await login(params);
      console.log(res);
      if (!res.success) throw res.msg;
      localStorage.setItem('userInfo', JSON.stringify(res.detail));
      navigate('/system', { replace: true });
    } catch (error) {
      message.error(error || '系统错误，请稍后再试');
    }
  };

  const checkName = (rule, value, type) => {
    const { emptyTip, reg, errorTip, maxLength, minLength } = rulesMap[type];
    if (!value) {
      return new Promise((resolve, reject) => {
        reject(new Error(emptyTip));
      });
    }
    if (!reg.test(value)) {
      return new Promise((resolve, reject) => {
        reject(new Error(errorTip));
      });
    }
    if (value.length > maxLength || value.length < minLength) {
      return new Promise((resolve, reject) => {
        reject(new Error(errorTip));
      });
    }
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  return (
    <div className='supply-chain-login'>
      <img className='login-bg' src={bg} alt='' />
      <div className='back-home' onClick={() => navigate('/', { replace: true })}></div>
      <div className='login-container'>
        <div className='login-title'>欢迎来到绿金供应链服务平台</div>
        <div className='login-type-tab'>
          {tabs.map((item, index) => (
            <div
              className={curIndex === index ? 'active' : ''}
              onClick={() => setCurIndex(index)}
              key={`tab-${index}`}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className='input-box'>
          <Form ref={formRef} autoComplete='off'>
            <Form.Item
              className='form-item'
              name='userName'
              rules={[
                {
                  validator: (rule, value) => checkName(rule, value, 'name')
                }
              ]}
            >
              <Input className='user-name input-common' placeholder='请输入用户名' />
            </Form.Item>
            <Form.Item
              className='form-item'
              name='password'
              rules={[
                {
                  validator: (rule, value) => checkName(rule, value, 'password')
                }
              ]}
            >
              <Input
                className='user-paddword input-common'
                placeholder='请输入密码'
                type='password'
                onPressEnter={handleLogin}
              />
            </Form.Item>
          </Form>
        </div>
        <div className='login-btn-box' onClick={handleLogin}>
          立即登录
        </div>
        <div className='forget-password'>
          <div className='left' onClick={() => setIsShowTip(true)}>
            忘记密码?
          </div>
          <div className={`query-pw-tip ${isShowTip ? 'show-tip' : 'hide-tip'}`}>
            <img className='tip-img' src={tip} alt='' />
            <span>密码重置请联系</span>
            <span>0571-63303271</span>
            <img className='close-img' src={close} alt='' onClick={() => setIsShowTip(false)} />
          </div>
          <div className='right'>
            <span>没有账号？</span>
            <span onClick={() => navigate('/register', { replace: true })}>去注册</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
