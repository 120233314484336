import axios from 'axios';
import { message } from 'antd';

const requests = axios.create({
  baseURL: process.env.REACT_APP_BASE_HOST,
  timeout: '100000'
});

requests.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

requests.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

requests.interceptors.response.use(
  res => {
    if (res.status === 200) {
      return new Promise((resolve, reject) => {
        resolve(res.data);
      });
    } else {
      // message.info('请求错误，请稍后重试');
    }
  },
  error => {
    let errorMsg = '';
    if (/network error/gi.test(error?.message)) {
      errorMsg = '请稍后重试';
      message.info('请稍后重试');
    } else if (/timeout/gi.test(error?.message)) {
      errorMsg = '请求超时，请稍后重试';
      message.info('请求超时，请稍后重试');
    } else {
      errorMsg = '请求错误，请稍后重试';
      message.info('请求错误，请稍后重试');
    }
    return Promise.reject(errorMsg);
  }
);

export default requests;
