import { useEffect } from 'react';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import './style.less';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams } from 'react-router-dom';
import fImg from '../../images/first-img.png';
import iconBank from '../../images/icon_bank.png';
import iconCoop from '../../images/icon_cooperation.png';
import iconStock from '../../images/icon_stockholder.png';
import chainBg from '../../images/chain-bg.png';
import fund from '../../images/fund-chain.png';
import logistics from '../../images/logistics-chain.png';
import merageThree from '../../images/merage-three.png';
import trade from '../../images/trade-chain.png';
import cooperateBg from '../../images/cooperate-bg.png';
import business from '../../images/business.png';
import aw from '../../images/arrow-white.png';
import { useNavigate } from 'react-router-dom';
import gongyinglian from '../../images/gongyingliangongsi.png';
import zengzhi from '../../images/zengzhifuneng.png';
import shuzihua from '../../images/shuzihua.png';

const AboutUs = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    document.documentElement.scrollTop = 0;
  }, []);

  const changeTab = url => {
    navigate(url);
  };

  return (
    <>
      <Header tab={tab} />
      <div className='content-container'>
        <div className='content-01'>
          <img src={fImg} alt='' />
          <div className='first-content'>
            <div className='content-title wow fadeInUp' data-wow-delay='200ms'>
              链资金 链效率 链信用
            </div>
            <div className='content-desc'>
              <p>绿金——领先供应链金融平台，区块链技术助力高效资金流转</p>
              <p>自研G链技术支撑金融服务平台，盘活链上资产、快速审核放款、</p>
              <p>科技赋能风控、传递信用机制</p>
            </div>
            <div className='btn-container'>
              <div className='join-we-box' onClick={() => navigate('/marketCooperate/2')}>
                <span>市场合作</span>
                <img src={aw} alt='' />
              </div>
              <div className='product-focus' onClick={() => navigate('/productFocus/1')}>
                产品聚焦
              </div>
            </div>
          </div>
        </div>
        <div className='content-02'>
          <div className='content-02-title wow fadeInUp' data-wow-delay='200ms'>
            绿金供应链平台
          </div>
          <div className='content-middle'>
            <div className='middle-left'>
              <p>杭州绿金供应链公司</p>
              <p>
                绿金供应链公司是绿地金创集团下属全资子公司，是集数据、
                科技及金融为一体的产业生态金融科技服务平台。
                公司缘起于绿地集团（600606.SH）的内部供应链场景，针对绿
                地集团地产、基建、商贸、酒旅、康养等多元场景及上下游的
                产业资源，搭建产业数字化的科技金融平台。目前聚焦于构建境内外一体化的石油全产业供应链体系。
              </p>
              <p>
                依托强大的股东方背景优势，通过信息化、标准化的信用体系，为用户提供全流程、定制化的金融解决方案。同时积极探索金融创新，致力于成为科技驱动的平台型金融服务商。
              </p>
              <div className='content-02-btn' onClick={() => navigate('/investRelation/5')}>
                投资者关系
              </div>
            </div>
            <img className='icon-01' src={gongyinglian} alt='' />
          </div>
        </div>
        <div className='content-03'>
          <div className='content-03-title wow fadeInUp' data-wow-delay='200ms'>
            G链的信用增值赋能
          </div>
          <div className='credit-desc'>
            <img className='desc-bg' src={zengzhi} alt='' />
            <div className='desc-content'>
              <p>自研G链技术支撑金融服务平台，将供应链上物流、信息流、资金</p>
              <p>流均记录在区块链上，追溯交易信息，监督交易状态，盘活链上资</p>
              <p>产，实现信息真实共享、信用机制传递。</p>
              <div className='credit-btn-container'>
                <div className='pro-focus' onClick={() => navigate('/productFocus/1')}>
                  产品聚焦
                </div>
                <div className='market' onClick={() => navigate('/marketCooperate/2')}>
                  市场合作
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='content-04'>
          <div className='content-04-title wow fadeInUp' data-wow-delay='200ms'>
            我们的优势
          </div>
          <div className='our-advantage'>
            <div>
              <img src={iconStock} alt='' />
              <p>股东资源</p>
              <p>强大的股东资源，与绿地集团多产业</p>
              <p>有效协同，提供多场景供应链金融服务</p>
            </div>
            <div>
              <img src={iconBank} alt='' />
              <p>旗下数字银行</p>
              <p>GLDB打通境内外供应链融通渠道</p>
              <p>提升融资便利性，降低融资成本</p>
            </div>
            <div>
              <img src={iconCoop} alt='' />
              <p>合作伙伴</p>
              <p>雄厚的自有资金，与其他大型国企合作，提供</p>
              <p>优质一站式金融服务解决方案</p>
            </div>
          </div>
        </div>
        <div className='invest-hope'>
          <div className='hope-title wow fadeInUp' data-wow-delay='200ms'>
            专注于数字化、场景化金融解决方案提供全方位服务
          </div>
          <div className='hope-item'>
            <img src={shuzihua} alt='' />
          </div>
        </div>
        <div className='content-05'>
          <div className='content-04-title wow fadeInUp' data-wow-delay='200ms'>
            成功案例
          </div>
          <div className='sucess-case'>
            浙江纳海（绿地集团子公司）开展原油代采业务，依托下游核心企业中兴石油的资质，进行应收账款融资
          </div>
          <div className='chain-box'>
            <img className='chain-bg' src={chainBg} alt='' />
            <div className='chain-content'>
              <img src={trade} alt='' />
              <img src={fund} alt='' />
              <img src={logistics} alt='' />
              <img src={merageThree} alt='' />
            </div>
          </div>
          <div className='cooperate-container'>
            <img className='cooperate-bg' src={cooperateBg} alt='' />
            <div className='cooperate-business-box'>
              <div className='jion-us-btn wow fadeInUp' data-wow-delay='200ms'>
                <span>合作伙伴</span>
              </div>
              <img className='business-img' src={business} alt='' />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
