import { useEffect } from 'react';
import './style.less';
import { useNavigate } from 'react-router-dom';
import dazongmaoyi from '../../images/dazongmaoyi.png';
import cunhuozhiya from '../../images/cunhuozhiya.png';
import baoliyewu from '../../images/baoliyewu.png';
import { message } from 'antd';

const SystemType = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};

  useEffect(() => {
    if (!userInfo.userName) {
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <div className='system-page'>
      <div className='go-home' onClick={() => navigate('/', { replace: true })}>
        返回首页
      </div>
      <div className='system-type'>
        <div
          className='type-item'
          onClick={() => {
            window.open('https://greenland-scf.ezfinancing.cn/#/');
          }}
        >
          <img src={baoliyewu} alt='' />
          <span>保理业务系统</span>
        </div>
        <div
          className='type-item'
          onClick={() => {
            message.info('敬请期待！');
          }}
        >
          <img src={cunhuozhiya} alt='' />
          <span>存货质押系统</span>
        </div>
        <div
          className='type-item'
          onClick={() => {
            message.info('敬请期待！');
          }}
        >
          <img src={dazongmaoyi} alt='' />
          <span>大宗贸易系统</span>
        </div>
      </div>
    </div>
  );
};

export default SystemType;
