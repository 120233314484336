import NewsDetail from '../views/newsDetail';
import AboutUs from '../views/aboutUs';
import InvestRelation from '../views/investRelationship';
import JoinWe from '../views/joinWe';
import MarketCooperate from '../views/marketCooperate';
import News from '../views/newsInformation';
import ProductFocus from '../views/productFocus';
import Error from '../views/error';
import Login from '../views/login';
import Register from '../views/register';
import SystemType from '../views/system';

const routers = [
  {
    path: '/',
    element: <AboutUs />
  },
  {
    path: '/investRelation/:tab?',
    element: <InvestRelation />
  },
  {
    path: '/joinWe/:tab?',
    element: <JoinWe />
  },
  {
    path: '/marketCooperate/:tab?',
    element: <MarketCooperate />
  },
  {
    path: '/news/:tab?',
    element: <News />
  },
  {
    path: '/productFocus/:tab?',
    element: <ProductFocus />
  },
  {
    path: '/newsDetail/:tab?/:id?',
    element: <NewsDetail />
  },
  {
    path: '/login/:from?',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/system',
    element: <SystemType />
  },
  {
    path: '*',
    element: <Error />
  }
];

export default routers;
