import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import routers from './router';

function App() {
  const GetRoutes = () => useRoutes(routers);
  return (
    <Router>
      <GetRoutes />
    </Router>
  );
}

export default App;
