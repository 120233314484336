import './style.less';
import erweima from '../../images/erweima.png';
import weixin from '../../images/weixin.png';
import qq from '../../images/qq.png';
import weibo from '../../images/weibo.png';

const Footer = () => {
  return (
    <div className='footer'>
      <p className='contact-us'>联系我们</p>
      <div className='company-info'>
        <div className='info-container'>
          <div className='service-left'>
            <div className='service-hotline'>
              <span className='text'>服务热线</span>
              <span>周一至周五（9:00-18:00）</span>
            </div>
            <div className='contact-hotline'>
              <span>联系热线</span>
              <span>021-63303271</span>
            </div>
          </div>
          <div className='service-middle'>
            <div className='company-email'>
              <span>公司邮箱</span>
              <span>luzhenying@greenlandjc.com</span>
            </div>
            <div className='contact-address'>
              <span>联系地址</span>
              <span>上海市黄浦区龙华东路858号绿地海外滩中心B座15楼</span>
            </div>
          </div>
        </div>
        <div className='service-right'>
          <img src={erweima} alt='' />
          <span>立即扫码咨询</span>
          <span>公众号在线客服</span>
        </div>
      </div>
      <div className='contact-type'>
        <img src={weixin} alt='' />
        <img src={weibo} alt='' />
        <img src={qq} alt='' />
      </div>
      <div className='copy-right'>
        <p>经Copyright@2020-2024 Ldrzzl all reserved 沪ICP备20002456号-1</p>
      </div>
    </div>
  );
};

export default Footer;
