import { useEffect, useState } from 'react';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import './style.less';
import Header from '../../components/header';
import Footer from '../../components/footer';
import bImg from '../../images/news-banner.png';
import preArrow from '../../images/116.png';
import nextArrow from '../../images/115.png';
import { Pagination } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NewsDetail = () => {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [articleId, setArticleId] = useState('1');
  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    setArticleId(id);
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className='news-detail-page'>
      <Header tab={tab} />
      <div className='news-information'>
        <div className='news-banner-box'>
          <img src={bImg} alt='' />
        </div>
        <div className='news-top'>
          <div className='news-title'>
            <span>News</span>
            <span>新闻动态</span>
            <span></span>
          </div>
        </div>
        <div className='news-content'>
          <div className='association-news'>
            {/* <div className='association-title'>关联新闻</div>
            <div className='association-list'>
              <div>长租公寓开启“存量市场”奔跑模式！</div>
              <div>长租公寓开启“存量市场”奔跑模式！</div>
              <div>长租公寓开启“存量市场”奔跑模式！</div>
              <div>长租公寓开启“存量市场”奔跑模式！</div>
              <div>长租公寓开启“存量市场”奔跑模式！</div>
            </div> */}
          </div>
          {articleId === '1' && <NewsOne />}
          {articleId === '2' && <NewsTwo />}
        </div>
        <div className='pagination'>
          <Pagination defaultCurrent={1} total={1} />
        </div>
        <div className='news-footer'>
          <div className='news-footer-left'>
            <div className='pre-piece'>
              <img src={preArrow} alt='' />
              <span>上一篇</span>
            </div>
            <div className='pre-piece-title'>新加坡数字银行之后，绿地又瞄准了香港虚拟资产牌照</div>
          </div>
          <div
            className='news-footer-middle'
            onClick={() => navigate('/news/3', { replace: true })}
          >
            返回首页
          </div>
          <div className='news-footer-right'>
            <div className='next-piece'>
              <span>上一篇</span>
              <img src={nextArrow} alt='' />
            </div>
            <div className='next-piece-title'>
              网商银行升级“大雁系统“：超1000家品牌接入，供应链金融加速数字化
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const NewsOne = () => {
  return (
    <div className='news-detail'>
      <div className='detail-title'>
        <p>新加坡数字银行之后，绿地又瞄准了香港虚拟资产牌照</p>
        <p>2023-06-28</p>
      </div>
      <div className='detail-content'>
        <p>继260万元从海外买入NFT数字“猿猴”藏品后，绿地近期又被市场捕捉到了虚拟资产业务新动态。</p>
        <p>
          事情要从去年香港特区政府所公布的《行政长官2022年施政报告》说起，报告提出将提升金融科技竞争力，当中便包括在虚拟资产方面草议引入有关服务提供者的法定发牌制度，以推动WEB3生态的发展。
        </p>
        <p>
          而后，香港特区政府投资推广署又于5月10日正式公布消息称，香港证券及期货事务监察委员会（SFC）6月1日将正式落实虚拟资产服务商新发牌制度。
        </p>
        <p>消息公布仅过去一周的时间，市场再次传出消息，绿地子公司即将申请香港虚拟资产牌照。</p>
        <p>
          自2021年设立绿联国际银行深入数字经济开始，绿地已燃起了对数字金融的热情，并随时间愈发“浓厚”。
        </p>
        <p>
          此次选择通过子公司绿地金创申请香港虚拟资产牌照，也侧面映照出绿地尝试进一步转型的可能性。
        </p>
        <p>数字金融长路 在数字金融这条道路上，绿地属于跟着时代走的一方。</p>
        <p>
          早在2015年，房地产企业“触网”营销及销售正成为大势所趋，越来越多房企在维持传统营销模式之外，也开始挖掘起与互联网相连接的营销模式。
        </p>
        <p>
          绿地也是如此，2015年4月，绿地集团宣布联手阿里巴巴蚂蚁金服和平安陆金所推出“绿地地产宝”产品，并将在蚂蚁金服的招财宝网络平台和绿地金融全资子公司贵州省绿地金融资产交易所上挂牌发行。
        </p>
        <p>
          据资料显示，绿地地产宝首期上线产品是一宗位于江西南昌的棚户区改造项目，首期发行总规模2亿元，约定年化收益率6.4%，产品期限为一年，认购资金2万元起。
          绿地方面彼时表示，该产品在挂牌半小时内已完成销售。
        </p>
        <p>随后于同年8月及2016年，绿地又前后上线了绿地广财App以及互联网金融平台吉客网。</p>
        <p>
          对于当时的绿地来说，“地产宝”的推出仅仅是发力地产金融的开始，据绿地管理层彼时公开采访记录，表示未来绿地地产宝不局限于绿地的自有项目，而是要建设成为互联网房地产金融平台，通过产品设计、包装，将社会闲散资金、机构资金与地产项目对接，为房企提供资金解决方案，同时为社会投资者提供投资产品。
        </p>
        <p>
          绿地对于金融业态的布局随即体现在同年披露的301亿元股权融资计划中，其中，110亿元用于开发房地产项目，90亿元用于偿还银行贷款，另外101亿元全部用于金融投资项目。
        </p>
        <p>
          激进的尝试往往会带来意想不到的变数，融资计划发布一年之后，绿地发布公告，对五个多月以前公布的定向增发预案进行调整，用于金融业务的方案全部取消。这也意味着，绿地设立投资基金、创立互联网创新金融公司等金融业务发展，暂时只能依靠其他渠道进行融资。
        </p>
        <p>
          不过，绿地对于数字金融业务的执着并未因此停止，一方面受彼时能源业务的影响，绿地急需寻找更有盈利潜力的业务线，另一方面也在选择借着互联网销售及支付大势挖掘合适的金融发展方向。
        </p>
        <p>
          于2016年，绿地金融方面收购了第三方支付机构山东省电子商务综合运营管理公司。值得注意的是，该公司曾于2012年6月27日获得互联网支付业务许可证，业务覆盖范围广至全国。据此，绿地拿下了一张互联网支付牌照。
        </p>
        <p>
          持续不断地加码金融业务使得绿地在市场间刷足了存在感，彼时，绿地金融高层还公开表示,绿地集团要求绿地金融2015年的利润占集团净利润将近50%。
        </p>
        <p>
          但从业绩数据来看，金融业务于2016-2019年的净利润总额并没有达到绿地总体净利润的二分之一左右，且占比在几年间增长并不明显。
        </p>
        <p>
          以2019年业绩为例，当期绿地所得利润总额为306亿元，而金融产业全年实现利润总额为35亿元，仅为11.44%。
        </p>
        <p>虚拟资产前路 虽然业务成效并未完全体现在财报数据中，但绿地依旧选择继续融入“金融圈”。</p>
        <p>
          2020年12月，历经一年的排队等待之后，绿地金融与蚂蚁集团同批拿下了新加坡数字银行牌照。彼时，申请新加坡数字银行牌照的企业财团合计21家，包括字节跳动、AMTD等科技大型企业，而绿地金融作为地产企业下的公司，与其他互联网企业共同入局实属例外。
        </p>
      </div>
    </div>
  );
};

const NewsTwo = () => {
  return (
    <div className='news-detail'>
      <div className='detail-title'>
        <p>网商银行升级“大雁系统“：超1000家品牌接入，供应链金融加速数字化</p>
        <p>2023-03-29</p>
      </div>
      <div className='detail-content'>
        <p>
          3月29日，2023数字供应链金融品牌峰会在杭举办，据悉，超过1000家品牌已接入网商银行“大雁系统”，覆盖20余个行业，在光伏、粮油、电动车等领
        </p>
        <p>域，市场排名前10的品牌接入率达80%，并且品牌对数字供应链金融的运用程度进一步加深：</p>
        <p>vivo连续3年借助供应链金融发新品，其经销商申请的贷款中，有60%用于采购新款手机；</p>
        <p>超威通过供应链金融支持旺季促销，为经销商补贴利息530多万，由贷款完成的采购额超6亿；</p>
        <p>洋河则用供应链金融拓展全国分销渠道，每10个获贷经销商中，有8个来自江苏省外；</p>
        <p>天福便利店用供应链金融提效5倍，资金流全面数字化，财务从1个人管200家店到能管1000家店。</p>
        <p>
          作为发新品、打旺季、拓渠道、提效率的工具，数字供应链金融的价值被重新定义。对品牌来说，供应链从成本中心转变为价值中心，通过接入“大雁系统”匹配数字金融，正逐步成为品牌发展的助推器。
        </p>
        <p>超过1000家品牌接入网商银行大雁系统</p>
        <p>
          据了解，“大雁系统”是网商银行基于供应链关系，开发的一套数字化综合金融解决方案，包含上游“中标贷”、下游“采购贷”等信贷服务，及线上资管工具“
        </p>
        <p>云资金”等综合金融服务。</p>
        <p>
          调研显示，8成品牌认为大雁系统对其业务增长有拉动作用。“数字供应链金融是提升销售的有效工具，
          ”超威集团副总裁开明敏表示，“因此我们在市场营销
        </p>
        <p>有专项预算，用于联合贴息，小投入带动大出货量。”</p>
        <p>越来越多的品牌，将其从“CFO工程”升级为“CEO工程”。</p>
        <p>
          天福连锁商业集团副总裁成世恩称，“我们发现数字供应链金融，不仅是个管账工具，还能给快速拓店提供帮助，所以现在是我亲自在抓。”2022年底，天福
        </p>
        <p>
          收购了4家便利店品牌，借助“大雁系统”，2000家新增加盟店财务数据实现无感切换，采购资金流一目了然。
        </p>
        <p>
          核心企业获益的背后，供应链上下游也成为获益方。从只有头部能获得融资，到中小微制造商、供货商、经销商、零售终端等都能贷款，平均通过率提升至80%。此外，经销商在周转高峰，还能获得上游品牌和网商银行的联合贴息，资金成本更低。
        </p>
        <p>网商银行行长冯亮认为新供应链金融应该具备的四大特征</p>
        <p>
          张志坚是李锦记在广东揭阳的经销商，他根据日常销售情况算过一笔账，每1万元利息成本，能撬动10万元的利润增长，生意越滚越大，疫情期间公司从1个人发展到30个人。
        </p>
        <p>
          今年李锦记还和网商银行联合贴息，提供
          60天的免息贷款，资金成本降到0，无疑更加促进了经销商的进货热情，整个春节旺季，他们通过信用贷款完成支付的采购额，占李锦记销售总额的25%。
        </p>
        <p>
          在企业信心普遍上扬的回暖时刻，为进一步落实与全国工商联共同发起的「助微计划」，网商银行对“大雁系统”进行了技术升级，接入“大雁系统”的千家品牌也将加入该计划，促进自身发展的同时，支持供应链小微的发展。
        </p>
        <p>黄高松，用网商贷6年开出10家奶茶店</p>
        <p>
          据悉，「助微计划」旨在通过普惠金融服务助力小微支持实体。2022年全国工商联发布的助微报告显示，该计划平均每月服务小微客户超300万，累计发放贷款超4万亿元。
        </p>
      </div>
    </div>
  );
};

export default NewsDetail;
