import { useState, forwardRef, useEffect } from 'react';
import './style.less';
import logo from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import loyoutIcon from '../../images/layout-icon.png';

const tabsList = [
  {
    text: '关于我们',
    url: '/'
  },
  {
    text: '产品聚焦',
    url: '/productFocus/1'
  },
  {
    text: '市场合作',
    url: '/marketCooperate/2'
  },
  {
    text: '资讯博客',
    url: '/news/3'
  },
  {
    text: '加入我们',
    url: '/joinWe/4'
  },
  {
    text: '投资者关系',
    url: '/investRelation/5'
  }
];

const Header = forwardRef(({ tab }, ref) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};

  useEffect(() => {
    setCurrentIndex(Number(tab) || 0);
  }, []);

  const tabClick = i => {
    setCurrentIndex(i);
    navigate(tabsList[i].url, { replace: true });
    document.documentElement.scrollTop = 0;
  };

  const handleLayout = () => {
    localStorage.removeItem('userInfo');
    location.reload();
  };

  return (
    <div className='header' ref={ref}>
      <img className='logo-container' src={logo} alt='' />
      <div className='tabs-container'>
        {tabsList.map((item, index) => {
          return (
            <span
              key={`tabs-${index}`}
              onClick={() => tabClick(index)}
              className={currentIndex === index ? 'active' : ''}
            >
              {item.text}
            </span>
          );
        })}
      </div>
      {!userInfo.userName ? (
        <div className='login-container' onClick={() => navigate('/login')}>
          <div className='login-left'>登录</div>
          <div className='login-right'>注册</div>
        </div>
      ) : (
        <div className='user-name'>
          <Popover
            title=''
            placement='bottom'
            content={() => (
              <div className='user-info'>
                <div className='info-item'>
                  <span className='item-title'>用户名:</span>
                  <span className='item-content'>{userInfo.userName}</span>
                </div>
                <div className='info-item'>
                  <span className='item-title'>注册时间:</span>
                  <span className='item-content'>{userInfo.createTime}</span>
                </div>
                <div className='info-item system-entry' onClick={() => navigate('/system')}>
                  <span className='item-title'>业务系统:</span>
                  <span className='item-content'>点击进入业务系统</span>
                </div>
                <div className='info-item'>
                  <span className='item-title'>登录状态:</span>
                  <div className='layout-btn' onClick={handleLayout}>
                    <span>退出登录</span>
                    <img src={loyoutIcon} alt='' />
                  </div>
                </div>
              </div>
            )}
            trigger='hover'
          >
            {userInfo.userName}
          </Popover>
        </div>
      )}
    </div>
  );
});

export default Header;
