import { useEffect } from 'react';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import './style.less';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams } from 'react-router-dom';
import bImg from '../../images/news-banner.png';
import newsImg from '../../images/news-img.png';
import news2 from '../../images/27.png';
import { Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';

const News = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Header tab={tab} />
      <div className='news-information'>
        <div className='news-banner-box'>
          <img src={bImg} alt='' />
        </div>
        <div className='news-top'>
          <div className='news-title'>
            <span>News</span>
            <span>新闻动态</span>
            <span></span>
          </div>
          <div className='news-type'>
            <span>全部新闻</span>
            <span>公司动态</span>
            <span>行业新闻</span>
          </div>
        </div>
        <div className='news-list'>
          <div className='news-list-item'>
            <div className='item-left'>
              <img className='news-img' src={newsImg} alt='' />
            </div>
            <div className='item-middle'>
              <span>28</span>
              <span>2023.06</span>
            </div>
            <div className='item-right'>
              <div className='news-info'>
                <p className='news-title'>新加坡数字银行之后，绿地又瞄准了香港虚拟资产牌照</p>
                <p className='news-content'>
                  你可以随意的设置投影的角度、长度、模糊等属性做出的效果非常逼真，效率非常高
                </p>
              </div>
              <div className='news-detail'>
                <span
                  className='detail-btn'
                  onClick={() => {
                    navigate('/newsDetail/3/1');
                  }}
                >
                  查看详情
                </span>
              </div>
            </div>
          </div>
          <div className='news-list-item'>
            <div className='item-left'>
              <img className='news-img' src={news2} alt='' />
            </div>
            <div className='item-middle'>
              <span>29</span>
              <span>2023.03</span>
            </div>
            <div className='item-right'>
              <div className='news-info'>
                <p className='news-title'>
                  网商银行升级“大雁系统“：超1000家品牌接入，供应链金融加速数字化
                </p>
                <p className='news-content'>数字供应链金融支持千家品牌助小微、促增长。</p>
              </div>
              <div className='news-detail'>
                <span
                  className='detail-btn'
                  onClick={() => {
                    navigate('/newsDetail/3/2', { replace: true });
                  }}
                >
                  查看详情
                </span>
              </div>
            </div>
          </div>
          <div className='pagination'>
            <Pagination defaultCurrent={1} total={2} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
