/* eslint-disable no-useless-escape */
import { useState, useEffect, useRef } from 'react';
import './style.less';
import { Input, Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Reg from '../../utils/regCheck';
import bg from '../../images/login-bg.png';
import { register } from '../../api/login';

const rulesMap = {
  name: {
    emptyTip: '*请输入用户名',
    errorTip: '*请输入数字加字母组合，4-12个字符',
    reg: Reg.nameReg,
    maxLength: 12,
    minLength: 4
  },
  password: {
    emptyTip: '*请输入密码',
    errorTip: '*设置登录密码，数字加字母组合，8-20个字符',
    reg: Reg.pwReg,
    maxLength: 20,
    minLength: 8
  },
  confirmPw: {
    emptyTip: '*请确认密码',
    errorTip: '*密码不一致',
    reg: Reg.pwReg,
    maxLength: 20,
    minLength: 8
  }
};

const Register = () => {
  const navigate = useNavigate();
  const [password, setPassWord] = useState('');
  const formRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRegister = () => {
    formRef.current
      .validateFields()
      .then(values => {
        console.log('valueObj=====>', values);
        _register(values);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const _register = async params => {
    try {
      const res = await register(params);
      console.log(res);
      if (!res.success) throw res.msg;
      message.info('注册成功');
    } catch (error) {
      message.error(error || '系统错误，请稍后再试');
    }
  };

  const checkName = (rule, value, type) => {
    const { emptyTip, reg, errorTip, maxLength, minLength } = rulesMap[type];
    if (type === 'confirmPw') {
      if (!value) {
        return new Promise((resolve, reject) => {
          reject(new Error(emptyTip));
        });
      }
      if (value !== password) {
        return new Promise((resolve, reject) => {
          reject(new Error(errorTip));
        });
      }
    }
    if (!value) {
      return new Promise((resolve, reject) => {
        reject(new Error(emptyTip));
      });
    }
    if (!reg.test(value)) {
      return new Promise((resolve, reject) => {
        reject(new Error(errorTip));
      });
    }
    if (value.length > maxLength || value.length < minLength) {
      return new Promise((resolve, reject) => {
        reject(new Error(errorTip));
      });
    }
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  return (
    <div className='supply-chain-register'>
      <img className='login-bg' src={bg} alt='' />
      <div className='back-home' onClick={() => navigate('/', { replace: true })}></div>
      <div className='register-container'>
        <div className='login-title'>欢迎注册绿金供应链服务平台</div>
        <Form ref={formRef} autoComplete='off'>
          <Form.Item
            className='form-item'
            name='userName'
            rules={[
              {
                validator: (rule, value) => checkName(rule, value, 'name')
              }
            ]}
          >
            <Input
              className='user-name input-common'
              placeholder='请设置用户名，数字加字母组合，4-12个字符'
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            className='form-item'
            name='password'
            rules={[
              {
                validator: (rule, value) => checkName(rule, value, 'password')
              }
            ]}
          >
            <Input
              className='user-name input-common'
              placeholder='设置登录密码，数字加字母组合，8-20个字符'
              onChange={e => setPassWord(e.target.value)}
              type='password'
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            className='form-item'
            name='confirmPw'
            rules={[
              {
                validator: (rule, value) => checkName(rule, value, 'confirmPw')
              }
            ]}
          >
            <Input
              className='user-name input-common'
              placeholder='确认密码'
              type='password'
              allowClear={true}
              onPressEnter={handleRegister}
            />
          </Form.Item>
        </Form>
        <div className='register-btn' onClick={handleRegister}>
          完成注册
        </div>
        <div className='go-login'>
          <span>已有帐号？</span>
          <span onClick={() => navigate('/login', { replace: true })}>去登录</span>
        </div>
      </div>
    </div>
  );
};

export default Register;
