import { useEffect, useState, useRef } from 'react';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import './style.less';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Radio, message } from 'antd';
import bImg from '../../images/hezuo-banner.png';
import tabActivity from '../../images/tab-activity.png';
import tianxie from '../../images/icon_tianxie.png';
import touzibuzhou from '../../images/191.png';
import successImg from '../../images/182.png';
import { submitForm } from '../../api/form';

const tabs = [
  { title: '我要融资', key: 1 },
  { title: '我想投资', key: 2 }
];

const formItemLayout = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 }
  }
};

const RULES_OBJ = {
  type: { errorTip: '请限制在20字以内', emptyTip: '请输入其他需求', length: 20 },
  desc: { errorTip: '请限制在200字以内', emptyTip: '请输入描述', length: 200 },
  name: { errorTip: '请输入正确的名字', emptyTip: '请输入您的姓名', minLength: 2, maxLength: 6 },
  phone: {
    errorTip: '请输入正确的手机号',
    emptyTip: '请输入您的手机号',
    length: 11,
    rules: new RegExp(/^1\d{2}\s?\d{4}\s?\d{4}$/)
  }
};

const MarketCooperate = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [isActivity, setIsActivity] = useState(0);
  const [type, setType] = useState('0');
  const [isSuccess, setIsSuccess] = useState(false);
  const formRef = useRef(null);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    document.documentElement.scrollTop = 0;
  }, []);

  const clickTab = i => {
    setIsActivity(i);
  };

  const checkName = (_rule, value, type) => {
    const emptyTip = RULES_OBJ[type].emptyTip;
    const errorTip = RULES_OBJ[type].errorTip;
    value = value && value.replace(/\s/g, '');
    let length, minLength, maxLength;
    length = RULES_OBJ[type].length;
    if (!value) {
      return new Promise((resolve, reject) => {
        reject(new Error(emptyTip));
      });
    }
    if (type === 'name') {
      minLength = RULES_OBJ[type].minLength;
      maxLength = RULES_OBJ[type].maxLength;
      if (value.length > maxLength || value.length < minLength) {
        return new Promise((resolve, reject) => {
          reject(new Error(errorTip));
        });
      }
    }
    if (type === 'phone') {
      if (!RULES_OBJ[type].rules.test(value.replace(/\s/g, ''))) {
        return new Promise((resolve, reject) => {
          reject(new Error(errorTip));
        });
      }
    }
    if (value.length > RULES_OBJ[type].length) {
      return new Promise((resolve, reject) => {
        reject(new Error(errorTip));
      });
    }
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  const formSubmit = () => {
    if (!userInfo) {
      navigate('/login/3');
      return;
    }
    formRef.current
      .validateFields()
      .then(values => {
        console.log('valueObj=====>', values);
        _submitForm({ ...values, questionnaireType: tabs[isActivity].key, ...userInfo });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const _submitForm = async params => {
    try {
      const res = await submitForm(params);
      console.log(res);
      if (!res.success) throw res.msg;
      setIsSuccess(true);
    } catch (error) {
      message.error(error || '系统错误，请稍后再试');
    }
  };

  return (
    <>
      <Header tab={tab} />
      <div className='market-cooperate'>
        <div className='market-banner'>
          <img src={bImg} alt='' />
          <div className='first-content'>
            <div className='content-title wow fadeInUp' data-wow-delay='100ms'>
              市场合作
            </div>
            <div className='content-desc'>
              <p>绿金供应链服务平台通过数据化，自动化，线上化的技术赋能，在融</p>
              <p>资服务的资产审核，合约签署以及核心企业确权等环节极大改善审核</p>
              <p>的低效和衍生成本问题。</p>
              <p>同时基于贸易流程可视化的属性，为资产方风险管理、贷后管理环节</p>
              <p>保驾护航。</p>
            </div>
          </div>
        </div>
        <div className='market-tabs'>
          {tabs.map((item, index) => {
            return (
              <div
                className={`tab-item ${isActivity === index ? 'activity' : ''}`}
                onClick={() => clickTab(index)}
                key={item.key}
              >
                <span>{item.title}</span>
                <div className='img-box'>
                  {isActivity === index && <img src={tabActivity} alt='' />}
                </div>
              </div>
            );
          })}
        </div>
        <div className='form-container'>
          {!isSuccess ? (
            <div className='rongzi-form common-form-style'>
              {isActivity === 1 && (
                <div className='touzibuzhou'>
                  <img src={touzibuzhou} alt='' />
                </div>
              )}
              <div className='tip-text'>
                <img src={tianxie} alt='' />
                <span>填写您的融资信息，我们将为您提供优质的服务</span>
              </div>
              <Form className='woyaorongzi' ref={formRef} autoComplete='off'>
                <Form.Item {...formItemLayout} label='类型' name='type' required={true}>
                  <Radio.Group onChange={e => setType(e.target.value)}>
                    <Radio value='0'>融资申请</Radio>
                    <Radio value='1'>业务需求</Radio>
                    <Radio value='2'>信息反馈</Radio>
                    <Radio value='3'>其他</Radio>
                  </Radio.Group>
                </Form.Item>
                {type === '3' && (
                  <Form.Item
                    {...formItemLayout}
                    className='financing-type-input'
                    name='otherType'
                    required={true}
                    rules={[
                      {
                        validator: (_rule, value) => checkName(_rule, value, 'type')
                      }
                    ]}
                  >
                    <Input placeholder='请输入其他需求' />
                  </Form.Item>
                )}
                <Form.Item
                  {...formItemLayout}
                  label='姓名'
                  name='name'
                  required={true}
                  rules={[
                    {
                      validator: (_rule, value) => checkName(_rule, value, 'name')
                    }
                  ]}
                >
                  <Input placeholder='请输入您的姓名' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='手机号'
                  name='phone'
                  required={true}
                  rules={[
                    {
                      validator: (_rule, value) => checkName(_rule, value, 'phone')
                    }
                  ]}
                >
                  <Input placeholder='请输入您的手机号码' maxLength={11} />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='主题'
                  name='theme'
                  required={true}
                  rules={[
                    {
                      validator: (_rule, value) => checkName(_rule, value, 'type')
                    }
                  ]}
                >
                  <Input placeholder='请输入主题' />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label='描述'
                  name='description'
                  required={true}
                  rules={[
                    {
                      validator: (_rule, value) => checkName(_rule, value, 'desc')
                    }
                  ]}
                >
                  <Input.TextArea placeholder='请输入描述信息' />
                </Form.Item>
                <Form.Item {...formItemLayout} label='其他' name='other'>
                  <Input.TextArea placeholder='其他需求（选填）' />
                </Form.Item>
              </Form>
              <div className='submit-btn' onClick={formSubmit}>
                提交
              </div>
            </div>
          ) : (
            <div className='submit-success'>
              <img src={successImg} alt='' />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MarketCooperate;
