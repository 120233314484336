import { useState, useEffect } from 'react';
import './style.less';
import { useThrottleFn } from 'ahooks';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams } from 'react-router-dom';
import fImg from '../../images/focus-banner.png';
import tabActivity from '../../images/tab-activity.png';
import fund from '../../images/icon_1.png';
import shenban from '../../images/card_1.png';
import shenpi from '../../images/card_2.png';
import yongkuan from '../../images/card_3.png';
import temp from '../../images/financing-desc.png';
import fangkuan from '../../images/card.png';
import yujing from '../../images/card1.png';
import cangchu from '../../images/card2.png';
import pledge from '../../images/pledge-desc.png';
import danjv from '../../images/155.png';
import xinyong from '../../images/156.png';
import tuangou from '../../images/157.png';
import zijinfang from '../../images/fund-square.png';
import rongzifang from '../../images/financing-square.png';
import zichanyunying from '../../images/139.png';
import fengxianguanli from '../../images/145.png';
import maoyixietong from '../../images/146.png';
import rongzixiaolv from '../../images/147.png';
import lianshangshouquan from '../../images/148.png';
import shuzihuobi from '../../images/149.png';
import zichanshuzi from '../../images/150.png';
import zhinengfengkong from '../../images/152.png';
import jinrongchanpin from '../../images/jinrongchanpin.png';
import liucheng from '../../images/liucheng.png';
import kekao from '../../images/224.png';
import linghuo from '../../images/225.png';
import wenjian from '../../images/226.png';
import miaoshu from '../../images/227.png';

const tabs = [
  { title: '业务产品版图', key: 0 },
  { title: '全景服务平台', key: 1 },
  { title: '科技赋能平台', key: 2 }
];

const ProductFocus = () => {
  const { tab } = useParams();
  const [isActivity, setIsActivity] = useState(0);

  useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    return () => {
      // window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    document.documentElement.scrollTop = 0;
  }, []);

  const clickTab = i => {
    setIsActivity(i);
    // scrollT(i);
  };

  const scrollT = i => {
    document.getElementsByClassName(`tab-${i}`)[0]?.scrollIntoView();
    window.scrollTo({
      top: window.scrollY,
      behavior: 'smooth'
    });
  };

  const { run: handleScroll } = useThrottleFn(
    () => {
      let currentKey = tabs[0].key;
      const h =
        document.getElementsByClassName('banner-container')[0].offsetHeight +
        document.getElementsByClassName('tabs-container')[0].offsetHeight;
      for (const item of tabs) {
        const element = document.getElementsByClassName(`tab-${item.key}`)[0];
        if (!element) continue;
        const rect = element.getBoundingClientRect();
        if (rect.top <= h) {
          currentKey = item.key;
        } else {
          break;
        }
      }
      setIsActivity(currentKey);
    },
    {
      leading: true,
      trailing: true,
      wait: 100
    }
  );

  return (
    <>
      <Header tab={tab} />
      <div className='product-focus'>
        <div className='banner-container'>
          <img src={fImg} alt='' />
          <div className='first-content'>
            <div className='content-title wow fadeInUp' data-wow-delay='100ms'>
              产品聚焦
            </div>
            <div className='content-desc'>
              <p>绿金供应链致力于向供应链上各参与方提供领先的金融科技</p>
              <p>解决方案，为资金方的风险控制智能化助力，同时为各融资</p>
              <p>企业提供专属业务服务与技术支持</p>
            </div>
          </div>
        </div>
        <div className='tabs-container'>
          {tabs.map((item, index) => {
            return (
              <div
                className={`tab-item ${isActivity === index ? 'activity' : ''}`}
                onClick={() => clickTab(index)}
                key={item.key}
              >
                <span>{item.title}</span>
                <div className='img-box'>
                  {isActivity === index && <img src={tabActivity} alt='' />}
                </div>
                {index === 0 && (
                  <div className='sub-tab-box'>
                    <div className='hover-box'></div>
                    <div className='sub-tab sub-tab-show'>
                      <div className='sub-item'>
                        <span>大宗贸易</span>
                        <span onClick={() => scrollT(1)}>代采订单融资</span>
                        <span onClick={() => scrollT(2)}>库存托管融资</span>
                      </div>
                      <div className='sub-item'>
                        <span>保理业务</span>
                        <span onClick={() => scrollT(3)}>应收账款融资</span>
                      </div>
                      <div className='sub-item'>
                        <span>交易所产品</span>
                        <span onClick={() => scrollT(4)}>绿金理财</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {isActivity === 0 && (
          <div className='business-sector'>
            <div className='fund-product tab-0'>
              <div className='product-left'>
                <p>供应链金融产品</p>
                <p>绿金供应链金融板块深耕于实体行业，基于核心企业良好的供应链管理能力，</p>
                <p>服务核心企业经销商生态圈。</p>
                <p>定制化专属金融服务方案，促进产业链循环，提升产业链运转效率。</p>
              </div>
              <div className='product-right'>
                <img src={jinrongchanpin} alt='' />
              </div>
            </div>
            <div className='financing-container tab-1'>
              <div className='financing-title title-common wow fadeInUp' data-wow-delay='100ms'>
                代采订单融资
              </div>
              <div className='financing-desc common-text'>
                <p>以未来兑现的价值符号为融资依据数字化贸易平台为核心企业上游经销商提供融资支持</p>
              </div>
              <div className='financing-advantage'>
                <img src={shenban} alt='' />
                <img src={shenpi} alt='' />
                <img src={yongkuan} alt='' />
              </div>
              <div className='success-temp'>
                <img src={temp} alt='' />
              </div>
            </div>
            <div className='financing-container pledge-container tab-2'>
              <div className='financing-title title-common wow fadeInUp' data-wow-delay='100ms'>
                库存托管融资
              </div>
              <div className='financing-desc common-text'>
                <p>数字化贸易平台实时生成电子仓单并同步各方，为核心企业上游经销商提供融资支持</p>
              </div>
              <div className='financing-advantage pledge-advantage'>
                <img src={fangkuan} alt='' />
                <img src={yujing} alt='' />
                <img src={cangchu} alt='' />
              </div>
              <div className='success-temp'>
                <img src={pledge} alt='' />
              </div>
            </div>
            <div className='financing-container accounts-container tab-3'>
              <div className='financing-title title-common wow fadeInUp' data-wow-delay='100ms'>
                应收帐款融资
              </div>
              <div className='financing-desc common-text'>
                <p>助力产业链上游的企业一站式无断点，以应收帐款为基础，快速获得流动资金</p>
              </div>
              <div className='financing-advantage accounts-advantage'>
                <img src={danjv} alt='' />
                <img src={xinyong} alt='' />
                <img src={tuangou} alt='' />
              </div>
            </div>
            <div className='financing-container lvjin-container tab-4'>
              <div className='financing-title title-common wow fadeInUp' data-wow-delay='100ms'>
                绿金理财
              </div>
              <div className='financing-desc common-text'>
                <p>数字化贸易平台实时生成电子仓单并同步各方，为核心企业上游经销商提供融资支持</p>
              </div>
              <div className='financing-advantage lvjin-advantage'>
                <img src={kekao} alt='' />
                <img src={linghuo} alt='' />
                <img src={wenjian} alt='' />
              </div>
              <div className='lvjin-temp'>
                <img src={miaoshu} alt='' />
              </div>
              <div className='liu-cheng-xiang-xi'>流程详细</div>
              <div className='liu-cheng'>
                <img src={liucheng} alt='' />
              </div>
            </div>
          </div>
        )}
        {isActivity === 1 && (
          <div className='financing-container service-container'>
            <div className='financing-title title-common wow fadeInUp' data-wow-delay='100ms'>
              全景服务平台
            </div>
            <div className='service-desc common-text'>
              <p>
                绿金供应链金融板块深耕于实体行业，基于核心企业良好的供应链管理能力，服务核心企业经销商生态圈。
              </p>
              <p>定制化专属金融服务方案，促进产业链循环，提升产业链运转效率。</p>
            </div>
            <div className='service-advantage'>
              <div className='advantage-title'>
                <img src={zijinfang} alt='' />
              </div>
              <div className='advantage-content'>
                <img src={zichanyunying} alt='' />
                <img src={fengxianguanli} alt='' />
              </div>
              <div className='advantage-title'>
                <img src={rongzifang} alt='' />
              </div>
              <div className='advantage-content'>
                <img src={maoyixietong} alt='' />
                <img src={rongzixiaolv} alt='' />
              </div>
            </div>
          </div>
        )}
        {isActivity === 2 && (
          <div className='financing-container count-container'>
            <div className='financing-title title-common wow fadeInUp' data-wow-delay='100ms'>
              数字化赋能平台
            </div>
            <div className='financing-desc common-text'>
              <p>以线上化、数据化、场景化、自动化的创新业务模式服务参与方</p>
            </div>
            <div className='financing-advantage count-advantage'>
              <img src={lianshangshouquan} alt='' />
              <img src={shuzihuobi} alt='' />
              <img src={zichanshuzi} alt='' />
              <img src={zhinengfengkong} alt='' />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProductFocus;
