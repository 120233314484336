import { useEffect } from 'react';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import './style.less';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams } from 'react-router-dom';
import jBanner from '../../images/joinwe-banner.png';
import zhichang from '../../images/188.png';
import bangong from '../../images/189.png';
import tuandui from '../../images/190.png';
import xinzi from '../../images/184.png';
import guoqi from '../../images/185.png';
import daixin from '../../images/186.png';
import shengri from '../../images/187.png';
import workBg from '../../images/19.png';

const JoinWe = () => {
  const { tab } = useParams();
  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <Header tab={tab} />
      <div className='join-we'>
        <div className='joinwe-banner'>
          <img src={jBanner} alt='' />
        </div>
        <div className='work-env'>
          <div className='env-title wow fadeInUp' data-wow-delay='100ms'>
            工作在绿金
          </div>
          <div className='show-env'>
            <img src={zhichang} alt='' />
            <img src={bangong} alt='' />
            <img src={tuandui} alt='' />
          </div>
        </div>
        <div className='work-welfare'>
          <img className='welfare-bg' src={workBg} alt='' />
          <div className='welfare-content'>
            <div className='welfare-title wow fadeInUp' data-wow-delay='100ms'>
              全方位的福利关怀
            </div>
            <div className='show-welfare'>
              <img src={xinzi} alt='' />
              <img src={guoqi} alt='' />
              <img src={daixin} alt='' />
              <img src={shengri} alt='' />
            </div>
          </div>
        </div>
        <div className='work-post'>
          <div className='post-title wow fadeInUp' data-wow-delay='100ms'>
            招聘职位
          </div>
          <div className='email-address'>简历投递邮箱：luzhenying@greenlandjc.com</div>
          <div className='post-table'>
            <div className='post-table-title'>
              <span>职位名称</span>
              <span>工作地点</span>
              <span>薪资待遇</span>
              <span>招聘人数</span>
            </div>
            <div className='post-table-item'>
              <span>业务拓展经理</span>
              <span>上海</span>
              <span>面议</span>
              <span>人数不限</span>
            </div>
            <div className='post-table-item'>
              <span>渠道经理</span>
              <span>上海</span>
              <span>面议</span>
              <span>人数不限</span>
            </div>
            <div className='post-table-item'>
              <span>资深风控专员</span>
              <span>上海</span>
              <span>面议</span>
              <span>人数不限</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JoinWe;
