import { useEffect } from 'react';
import { WOW } from 'wowjs';
import '../../../node_modules/wowjs/css/libs/animate.css';
import './style.less';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useParams } from 'react-router-dom';
import touziBanner from '../../images/touzi-banner.png';
import lingxian from '../../images/168.png';
import keji from '../../images/169.png';
import kehu from '../../images/176.png';
import lvdiTitle from '../../images/178.png';
import shukeTitle from '../../images/177.png';
import lvdiImg from '../../images/lvdijituan.png';
import shukeImg from '../../images/lvdishuke.png';
import course from '../../images/193.png';
import shujv1 from '../../images/172.png';
import shujv2 from '../../images/173.png';
import shujv3 from '../../images/174.png';
import shujv4 from '../../images/175.png';

const InvestRelation = () => {
  const { tab } = useParams();
  useEffect(() => {
    const wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    });
    wow.init();
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <Header tab={tab} />
      <div className='invest-relation'>
        <div className='invest-banner'>
          <img src={touziBanner} alt='' />
        </div>
        <div className='invest-hope'>
          <div className='hope-title wow fadeInUp' data-wow-delay='100ms'>
            绿金的目标
          </div>
          <div className='hope-item'>
            <img src={lingxian} alt='' />
            <img src={keji} alt='' />
            <img src={kehu} alt='' />
          </div>
        </div>
        <div className='gudong-beijing'>
          <div className='beijing-title wow fadeInUp' data-wow-delay='100ms'>
            股东背景
          </div>
          <div className='compone-desc'>
            <div className='lvdijituan'>
              <div className='left'>
                <img src={lvdiTitle} alt='' />
                <p>绿地集团是一家全球经营的特大型企业集团，创立于1992年，</p>
                <p>总部设立于中国上海；成立至今已在全球范围内形成了“以房地产、基</p>
                <p>建为主业、消费、金融、健康、科创等产业协同发展“的多元经营格</p>
                <p>局，连续九年入围《财富》全球企业500强，2022年位列第125位</p>
              </div>
              <div className='right'>
                <img src={lvdiImg} alt='' />
              </div>
            </div>
            <div className='lvdishuke'>
              <div className='right'>
                <img src={shukeImg} alt='' />
              </div>
              <div className='left'>
                <img src={shukeTitle} alt='' />
                <p>绿地数科是针对绿地集团地产、基建、商贸、酒旅、康养等多元场景及上下游的</p>
                <p>产业资源，集数据、科技及金融为一体的产业生态金融科技服务平台</p>
              </div>
            </div>
          </div>
        </div>
        <div className='course-box'>
          <div className='course-title wow fadeInUp' data-wow-delay='100ms'>
            发展里程碑
          </div>
          <img src={course} alt='' />
        </div>
        {/* <div className='shujv-caiwu'>
          <div className='shujv-title wow fadeInUp' data-wow-delay='100ms'>
            工作在绿金
          </div>
          <div className='shujv-item'>
            <img src={shujv1} alt='' />
            <img src={shujv2} alt='' />
            <img src={shujv3} alt='' />
            <img src={shujv4} alt='' />
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default InvestRelation;
